/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://zxbqcpnnszayhcwpywbounjm5u.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "plaid",
            "endpoint": "https://i6bos0vw0f.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "transunion",
            "endpoint": "https://8j8256i4k3.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "docusign",
            "endpoint": "https://n61wgscsd9.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "expenses",
            "endpoint": "https://hk6cp42rfh.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "income",
            "endpoint": "https://xuofqlgzwl.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "offer",
            "endpoint": "https://ifdmouoqad.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "twilio",
            "endpoint": "https://xy98hnwujc.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "Cognito",
            "endpoint": "https://pfo62w7qr0.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "risk",
            "endpoint": "https://yj5k5lehp0.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "outreach",
            "endpoint": "https://oum1dh3x6f.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "partnerproxy",
            "endpoint": "https://c4qbzsu7w4.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "debt",
            "endpoint": "https://bwdfy07enl.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "budget",
            "endpoint": "https://p0b86vttch.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        },
        {
            "name": "decision",
            "endpoint": "https://v96dpi78cj.execute-api.us-east-2.amazonaws.com/navicore",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:d5bdbbac-e21d-498c-8a70-e7a96299b512",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_MIR4w9gZ7",
    "aws_user_pools_web_client_id": "4amnulee9a4kcu0flv1bhfi1cc",
    "oauth": {}
};


export default awsmobile;
